import { useState } from 'react';

export const useField = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);

  const onChange = e => {
    const { value } = e.target;
    if (value.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
    setValue(e.target.value);
  };

  const reset = () => {
    setValue('');
  };

  const validate = () => {
    if (!value) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  return { value, onChange, error, reset, validate };
};
