import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormContext } from 'react-hook-form';
import { useField } from 'hooks/useField.hook';

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: '#293241',
  },
  cellHeader: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: 'white',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#e3e3e3',
    },
    '&:nth-child(even)': {
      backgroundColor: '#a9a9a9',
    },
  },
}));

export const ExercisesTable = () => {
  const [isFormOpen, toggleForm] = useState(false);

  const nameField = useField('');

  const frequencyField = useField('');

  const durationField = useField('');

  const noteField = useField('');

  const form = useFormContext();

  const classes = useStyles();

  const sports = form.watch('sports');

  const onDeleteSportHandler = item => {
    const _sports = sports.filter(sport => sport._id !== item._id);
    form.setValue('sports', _sports);
  };

  const onOpenFormHandler = () => {
    toggleForm(prevState => !prevState);
  };

  const onSubmitHandler = () => {
    const activity = nameField.value;
    const daysPerWeek = frequencyField.value;
    const duration = durationField.value;
    const note = noteField.value;

    const exerciseValidation = nameField.validate();
    const frequencyValidation = frequencyField.validate();
    const durationValidation = durationField.validate();

    if (exerciseValidation || durationValidation || frequencyValidation) {
      const payload = {
        activity,
        daysPerWeek,
        duration,
        note,
        _id: nanoid(),
      };
      const _sports = [...sports, payload];
      form.setValue('sports', _sports);
      toggleForm(false);
      nameField.reset();
      frequencyField.reset();
      durationField.reset();
      noteField.reset();
    }
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell align="center" className={classes.cellHeader}>
                Tipo de ejercicio
              </TableCell>
              <TableCell align="center" className={classes.cellHeader}>
                Frecuencia
              </TableCell>
              <TableCell align="center" className={classes.cellHeader}>
                Horas a la semana
              </TableCell>
              <TableCell align="center" className={classes.cellHeader}>
                Notas
              </TableCell>
              <TableCell align="center" className={classes.cellHeader}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sports.map(sport => (
              <TableRow key={nanoid()} className={classes.row}>
                <TableCell align="center">{sport.activity}</TableCell>
                <TableCell align="center">{sport.daysPerWeek}</TableCell>
                <TableCell align="center">{sport.duration}</TableCell>
                <TableCell align="center">{sport.note}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Eliminar">
                    <IconButton
                      onClick={() => {
                        onDeleteSportHandler(sport);
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onOpenFormHandler}
        >
          Agregar nueva actividad física
        </Button>
      </Box>
      {isFormOpen && (
        <Box mt={2}>
          <Grid container spacing={4} style={{ marginBottom: '16px' }}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Ejercicio *"
                color="secondary"
                helperText={
                  nameField.error
                    ? 'Este campo es requerido'
                    : 'Nombre del ejercicio'
                }
                error={nameField.error}
                value={nameField.value}
                onChange={nameField.onChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Frecuencia *"
                color="secondary"
                helperText={
                  frequencyField.error
                    ? 'Este campo es requerido'
                    : 'Frecuencia del ejercicio'
                }
                error={frequencyField.error}
                value={frequencyField.value}
                onChange={frequencyField.onChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Duración *"
                color="secondary"
                helperText={
                  durationField.error
                    ? 'Este campo es requerido'
                    : 'Duración del ejercicio'
                }
                error={durationField.error}
                value={durationField.value}
                onChange={durationField.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notas"
                color="secondary"
                value={noteField.value}
                onChange={noteField.onChange}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSubmitHandler}
          >
            Agregar
          </Button>
        </Box>
      )}
    </Box>
  );
};
