import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { set } from 'date-fns';

import { Box, Button, ThemeProvider, Typography } from '@material-ui/core';

import { WellnubCheckboxInput } from '@wellnub/web-common';

import { generateTheme } from 'utils/helpers';

import { setModalStatus } from 'redux/slices/modal.slice';

import {
  useAcceptLinkRequest,
  useCreateAppointmentWithNutritionist,
} from 'hooks/user.hook';
import { useRequestGetInTouch } from 'hooks/nutritionists.hook';
import { useAnalyticsEventTracker } from 'hooks/analytics.hook';

import { useStyles } from './NutritionistInvitationConditions.styles';

const NutritionistInvitationConditionsForm = () => {
  const { linkRequestId } = useSelector(state => state.user);
  const {
    _id,
    mode,
    name,
    getInTouchData: {
      shouldLinkRequest,
      office,
      serviceId,
      serviceMode,
      date,
      time,
      withAppointment,
      comments,
      sex,
      ...rest
    },
  } = useSelector(state => state.nutritionist);

  const { mutateAsync: requestInfo, isLoading } = useRequestGetInTouch();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      personalInfo: false,
      link: false,
      previousAppointments: false,
    },
  });

  const { mutate: acceptLinkRequest, isLoading: isLoadingAccept } =
    useAcceptLinkRequest();

  const { mutateAsync: createAppointment } =
    useCreateAppointmentWithNutritionist();

  const { eventTracker } = useAnalyticsEventTracker('user-click');

  const dispatch = useDispatch();

  const classes = useStyles();

  const onSubmitHandler = data => {
    if (mode === 'LINK') {
      eventTracker('nutritionist-link-confirmation', `${name}-links`);
      requestInfo({
        id: _id,
        payload: { serviceMode, serviceId, sex, notes: comments, ...rest },
        shouldLinkRequest,
      })
        .then(response => {
          if (withAppointment === 'yes') {
            createAppointment({
              nutritionist: _id,
              service: serviceId,
              mode: serviceMode === 'site' ? 'onsite' : serviceMode,
              office,
              linkRequest: response.data?.linkRequest,
              timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
              startDate: set(date, {
                hours: new Date(time).getHours(),
                minutes: new Date(time).getMinutes(),
                seconds: 0,
              }),
            }).then(() => {
              dispatch(
                setModalStatus({
                  name: 'GET_IN_TOUCH_NUTRITION_STEP_2',
                  isActive: false,
                }),
              );
            });
          }
        })
        .catch(_ => {});
    } else {
      acceptLinkRequest(linkRequestId);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
        <WellnubCheckboxInput
          fullWidth
          name="personalInfo"
          color="primary"
          label="Al aceptar esta vinculación, autorizo que mi nutriólogo utilice Wellnub para guardar y consultar mis datos personales y acepto que tendrá acceso a mi información personal y clínica que haya compartido con Wellnub previamente, incluyendo:"
          control={control}
          rules={{
            required: 'Es necesario aceptar para continuar',
          }}
          helperText=""
        />
        <ul className={classes.list}>
          <li>
            <Typography>
              - Información de contacto (teléfono y correo electrónico)
            </Typography>
          </li>
          <li>
            <Typography>- Expediente médico</Typography>
          </li>
          <li>
            <Typography>
              - Análisis de composición corporal y bioquímicos
            </Typography>
          </li>
        </ul>
        <Box mt={4}>
          <WellnubCheckboxInput
            fullWidth
            name="link"
            color="primary"
            label="Confirmo que al aceptar esta vinculación, se desactivará el vínculo que, en su caso, tuviera con otro nutriólogo previamente"
            control={control}
            rules={{
              required: 'Es necesario aceptar para continuar',
            }}
            helperText=""
          />
        </Box>
        <Box mt={4}>
          <WellnubCheckboxInput
            fullWidth
            name="previousAppointments"
            color="primary"
            label="Confirmo que al aceptar esta vinculación, se cancelarán las citas agendadas que, en su caso, tuviera con otro nutriólogo previamente."
            control={control}
            rules={{
              required: 'Es necesario aceptar para continuar',
            }}
            helperText=""
          />
        </Box>
      </ThemeProvider>
      <Box mt={2} textAlign="center">
        <Button
          variant="contained"
          type="submit"
          disabled={isLoadingAccept || isLoading}
        >
          Aceptar
        </Button>
      </Box>
    </form>
  );
};

export default NutritionistInvitationConditionsForm;
