import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  ThemeProvider,
  Typography,
} from '@material-ui/core';

import { useStyles } from './DeleteAccount.styles';
import { useState } from 'react';
import { generateTheme } from '../../utils/helpers';
import { useDispatch } from 'react-redux';
import { MODAL_NAMES } from '../../utils/constants';
import { setModalStatus } from '../../redux/slices/modal.slice';

const DeleteAccountPage = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  const onDeleteAccountHandler = () => {
    dispatch(
      setModalStatus({
        name: MODAL_NAMES.DELETE_ACCOUNT,
        isActive: true,
      }),
    );
  };

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Typography
          variant="h4"
          className={classes.title}
          style={{ paddingBottom: '16px' }}
        >
          Deseo cerrar mi cuenta
        </Typography>
        <Typography style={{ paddingBottom: '16px' }}>
          Al realizar esta acción, perderás acceso a todos los servicios de
          Wellnub, y:
        </Typography>
        <ul style={{ paddingBottom: '16px' }} className={classes.list}>
          <li style={{ paddingBottom: '8px' }}>
            <Typography>
              - Los pases enviados por tu nutriólogo(a), consultas pendientes y
              planes de alimentación serán cancelados automáticamente.
            </Typography>
          </li>
          <li style={{ paddingBottom: '8px' }}>
            <Typography>
              - Ya no podrás visualizar tus resultados de análisis InBody.
            </Typography>
          </li>
          <li style={{ paddingBottom: '8px' }}>
            <Typography>
              - Los pases que hayas comprado se perderán y no serán
              reembolsables.
            </Typography>
          </li>
          <li style={{ paddingBottom: '8px' }}>
            <Typography>
              - Podrás volver a crear una cuenta en el futuro, pero tu
              información anterior no estará disponible
            </Typography>
          </li>
        </ul>
        <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
          <FormControlLabel
            label="Entiendo y acepto que al eliminar mi cuenta, todos los datos serán eliminados de forma permanente y no podré recuperarlos"
            control={
              <Checkbox
                checked={isAccepted}
                onChange={() => {
                  setIsAccepted(prevState => !prevState);
                }}
                color="primary"
              />
            }
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Button
              variant="contained"
              disabled={!isAccepted}
              onClick={onDeleteAccountHandler}
            >
              Aceptar
            </Button>
          </Box>
        </ThemeProvider>
      </Container>
    </Box>
  );
};

export default DeleteAccountPage;
