import { useDispatch, useSelector } from 'react-redux';

import { Box, ThemeProvider, Typography } from '@material-ui/core';

import WithModal from '../../HOC/WithModal/WithModal.component';

import SetDefaultCardForm from '../../Forms/SetDefaultCard/SetDefaultCardForm.component';
import DeleteCardForm from '../../Forms/DeleteCard/DeleteCardForm.component';
import ResetPasswordForm from '../../Forms/ResetPassword/ResetPasswordForm.component';
import GetInTouchNutritionistForm from '../../Forms/GetInTouch/Nutritionist/GetInTouchNutritionistForm.component';
import SignInForm from '../../Forms/SignIn/SignInForm.component';
import FirstSignInForm from '../../Forms/FirstSignIn/FirstSignInForm.component';

import { generateTheme } from 'utils/helpers';
import NutritionistInvitationConditionsForm from '../../Forms/NutritionistInvitationConditions/NutritionistInvitationConditions.component';
import TermsAndConditionsForm from 'components/Forms/TermsAndConditions/TermsAndConditions.component';
import CancelPaymentSubscriptionPanel from '../Panels/CancelPaymentSubscription/CancelPaymentSubscription.component';
import PendingLinkRequestWarningPanel from '../Panels/PendingLinkRequestWarning/PendingLinkRequestWarningPanel.component';
import AppointmentSendedByNutritionistPanel from '../Panels/AppointmentSendedByNutritionistConfirmation/AppointmentSendedByNutritionistPanel.component';
import CancelAppointmentWithNutritionistWarningPanel from '../Panels/CancelAppointmentWithNutritionistWarning/CancelAppointmentWithNutritionistWarningPanel.component';
import LinkRequestWarningPanel from '../Panels/LinkRequestWarning/LinkRequestWarningPanel.component';
import { setModalBehavior } from '../../../redux/modalBehaviors/actions';
import { MODAL_NAMES } from '../../../utils/constants';
import { setModalStatus } from '../../../redux/slices/modal.slice';
import UnlinkNutritionistPanel from '../Panels/UnlinkNutritionist/UnlinkNutritionistPanel.component';
import RemoveBiochemicalPlanWarning from '../Panels/RemoveBiochemicalPlanWarning/RemoveBiochemicalPlanWarning.component';
import DeleteAccountModal from '../Panels/DeleteAccount/DeleteAccountModal.component';

const ModalManager = () => {
  const { name, isActive } = useSelector(state => state.modal);

  const dispatch = useDispatch();

  switch (name) {
    case 'SET_DEFAULT_CARD':
      return (
        <WithModal
          open={isActive}
          name="SET_DEFAULT_CARD"
          title="Cambiar tarjeta principal"
          BodyComponent={<SetDefaultCardForm />}
        />
      );
    case 'DELETE_CARD':
      return (
        <WithModal
          open={isActive}
          name="DELETE_CARD"
          title="Eliminar tarjeta"
          BodyComponent={<DeleteCardForm />}
        />
      );
    case 'RESET_PASSWORD':
      return (
        <WithModal
          open={isActive}
          name="RESET_PASSWORD"
          title="Recuperar contraseña"
          BodyComponent={<ResetPasswordForm />}
        />
      );
    case 'GET_IN_TOUCH_NUTRITION':
      return (
        <WithModal
          open={isActive}
          name="GET_IN_TOUCH_NUTRITION"
          title="Paso 1: Sólo unos datos adicionales"
          BodyComponent={<GetInTouchNutritionistForm />}
        />
      );
    case 'GET_IN_TOUCH_NUTRITION_STEP_2':
      return (
        <WithModal
          open={isActive}
          name="GET_IN_TOUCH_NUTRITION_STEP_2"
          title="Paso 2: Confirmación de vinculación con nutriólogo"
          BodyComponent={<NutritionistInvitationConditionsForm />}
        />
      );
    case 'SPECIAL_SIGN_IN':
      return (
        <WithModal
          open={isActive}
          name="SPECIAL_SIGN_IN"
          title="Para poder continuar inicia sesión"
          BodyComponent={
            <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
              <Box textAlign="center">
                <SignInForm />
                <Box mt={2}>
                  <Typography variant="body2">
                    ¿No tienes cuenta?{' '}
                    <Typography
                      component="span"
                      variant="h6"
                      style={{ color: 'teal', cursor: 'pointer' }}
                      onClick={() => {
                        dispatch(
                          setModalStatus({
                            name: 'SPECIAL_SIGN_IN',
                            isVisible: false,
                          }),
                        );
                        setTimeout(() => {
                          dispatch(
                            setModalBehavior({
                              name: MODAL_NAMES.SIGN_UP,
                              isVisible: true,
                            }),
                          );
                        }, 1000);
                      }}
                    >
                      regístrate gratis
                    </Typography>
                  </Typography>
                  <Typography variant="body2">
                    ¿Olvidaste tu contraseña?&nbsp;
                    <Typography
                      component="span"
                      variant="h6"
                      style={{ color: 'teal', cursor: 'pointer' }}
                      onClick={() => {
                        dispatch(
                          setModalStatus({
                            name: 'SPECIAL_SIGN_IN',
                            isVisible: false,
                          }),
                        );
                        setTimeout(() => {
                          dispatch(
                            setModalStatus({
                              name: 'RESET_PASSWORD',
                              isActive: true,
                            }),
                          );
                        }, 1000);
                      }}
                    >
                      Da click aquí
                    </Typography>
                  </Typography>
                  <Typography variant="body2">
                    ¿Eres nutriólogo?{' '}
                    <Typography
                      component="span"
                      variant="h6"
                      style={{ color: 'teal' }}
                    >
                      {' '}
                      <a
                        href="https://nutrition.wellnub.com/signIn"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'teal' }}
                      >
                        Inicia sesión aquí
                      </a>
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </ThemeProvider>
          }
        />
      );
    case 'FIRST_SIGN_IN':
      return (
        <WithModal
          open={isActive}
          name="FIRST_SIGN_IN"
          title="Bienvenido a Wellnub"
          BodyComponent={<FirstSignInForm />}
        />
      );
    case 'FIRST_SIGN_IN_TERMS_AND_CONDITIONS':
      return (
        <WithModal
          open={isActive}
          name="FIRST_SIGN_IN_TERMS_AND_CONDITIONS"
          title="Bienvenido a Wellnub"
          showCloseButton={false}
          BodyComponent={<TermsAndConditionsForm />}
        />
      );
    case 'NUTRITIONIST_INVITATION_ALERT':
      return (
        <WithModal
          open={isActive}
          name="NUTRITIONIST_INVITATION_ALERT"
          title="Confirmación de vinculación con nutriólogo"
          BodyComponent={<NutritionistInvitationConditionsForm />}
        />
      );
    case 'CANCEL_PAYMENT_SUBSCRIPTION':
      return (
        <WithModal
          open={isActive}
          name="CANCEL_PAYMENT_SUBSCRIPTION"
          title="Cancelar suscripción"
          BodyComponent={<CancelPaymentSubscriptionPanel />}
        />
      );
    case 'HAS_PENDING_LINK_REQUEST_WARNING':
      return (
        <WithModal
          open={isActive}
          name="HAS_PENDING_LINK_REQUEST_WARNING"
          title="Aviso importante"
          BodyComponent={<PendingLinkRequestWarningPanel />}
        />
      );
    case 'APPOINTMENT_SENDED_BY_NUTRITIONIST_CONFIRMATION':
      return (
        <WithModal
          open={isActive}
          name="APPOINTMENT_SENDED_BY_NUTRITIONIST_CONFIRMATION"
          title="Confirma tu consulta"
          BodyComponent={<AppointmentSendedByNutritionistPanel />}
        />
      );
    case 'CANCEL_APPOINTMENT_WITH_NUTRITIONIST_WARNING':
      return (
        <WithModal
          open={isActive}
          name="CANCEL_APPOINTMENT_WITH_NUTRITIONIST_WARNING"
          title="Cancelar consulta"
          BodyComponent={<CancelAppointmentWithNutritionistWarningPanel />}
        />
      );
    case 'LINK_REQUEST_WARNING':
      return (
        <WithModal
          open={isActive}
          name="LINK_REQUEST_WARNING"
          title="Aviso importante"
          BodyComponent={<LinkRequestWarningPanel />}
        />
      );
    case 'UNLINK_NUTRITIONIST_WARNING':
      return (
        <WithModal
          open={isActive}
          name="UNLINK_NUTRITIONIST_WARNING"
          title="Deseo desvincularme"
          BodyComponent={<UnlinkNutritionistPanel />}
        />
      );
    case 'REMOVE_BIOCHEMICAL_PLAN_WARNING':
      return (
        <WithModal
          open={isActive}
          name="REMOVE_BIOCHEMICAL_PLAN_WARNING"
          title="Advertencia"
          BodyComponent={<RemoveBiochemicalPlanWarning />}
        />
      );
    case MODAL_NAMES.DELETE_ACCOUNT:
      return (
        <WithModal
          open={isActive}
          name={MODAL_NAMES.DELETE_ACCOUNT}
          title="Advertencia"
          BodyComponent={<DeleteAccountModal />}
        />
      );
    default:
      return null;
  }
};

export default ModalManager;
