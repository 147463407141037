import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from '../../../../redux/slices/modal.slice';
import { MODAL_NAMES } from '../../../../utils/constants';

const DeleteAccountModal = () => {
  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: MODAL_NAMES.DELETE_ACCOUNT,
        isActive: false,
      }),
    );
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: MODAL_NAMES.DELETE_ACCOUNT,
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography
        variant="h6"
        style={{ paddingBottom: '8px', textAlign: 'center' }}
      >
        ¿Estás seguro que deseas cerrar tu cuenta en Wellnub?
      </Typography>
      <Typography style={{ textAlign: 'center' }}>
        Esta acción no se podrá revertir
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        pt={2}
      >
        <Button variant="contained" color="primary" onClick={onAcceptHandler}>
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteAccountModal;
